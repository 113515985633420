// Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
$bp-larger-than-mobile    : "min-width: 400px" !default;
$bp-larger-than-phablet   : "min-width: 550px" !default;
$bp-larger-than-tablet    : "min-width: 750px" !default;
$bp-larger-than-desktop   : "min-width: 1000px" !default;
$bp-larger-than-desktophd : "min-width: 1200px" !default;

// Colors
$light-grey: #e1e1e1 !default;
$dark-grey: #333 !default;
$primary-color: #3C1874 !default;
$secondary-color: lighten($dark-grey, 13.5%) !default;
$border-color: #bbb !default;
$link-color: #1eaedb !default;
$font-color: #3C1874 !default;

$teal: #15d0d4 !default;
$gray-teal: #0d8d8f !default;
$pure-purple: #3C1874 !default;
$purple-gray: #283747 !default;
$purple-gray-transparent: #283747CC !default;
$cloud: #f3f3f3 !default;

// Typography
$font-family: ‘Monserrat’, sans-serif;

//Grid Variables
$container-width: 960px !default;
$container-width-larger-than-mobile: 85% !default;
$container-width-larger-than-phablet: 80% !default;
$total-columns: 12 !default;
$column-width: 100 / $total-columns !default; // calculates individual column width based off of # of columns
$column-margin: 4% !default; // space between columns

// Misc
$global-radius: 4px !default;
