// HOLDINGS PAGE
.holdings {
  border-radius: 10px;
  width: 100%;
  margin: 50px auto;
  background-color: $purple-gray-transparent;
  padding: 20px 10%;
  h5 {
    text-align: center;
    font-size: 3rem;
    font-style: italic;
    margin-bottom: 60px;
    letter-spacing: 0.001rem;
    -webkit-text-stroke: 1.4px #3C1874;
  }
  @media all and (max-width: 500px) {
    h5 {
      font-size: 2rem;
      white-space: nowrap;
    }
  }
  h2, h4, h5, h6, p {
    color: $cloud;
    cursor: default;

  }
  h4 {
    line-height: 1.35;
    letter-spacing: 0.01rem;
    font-weight: 800;
    color: white;
    -webkit-text-stroke: 1.4px #3C1874;
    font-style: italic;
  }
  .holdings-secrets {
    .pending-secrets-list, .active-secrets-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      // align-items: center;
      max-width: 100%;
    }
  }
}
.secret-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  max-width: 100%;
  margin-bottom: 35px;
  background-color: $purple-gray;
  padding: 20px;
  border-radius: 5px;
  img {
    width: 280px;
    max-height: 280px;
    max-width: 100%;
  }
  .secret-text {
    color: $cloud;
    font-size: 16px;
    max-width: 260px;
    margin: 20px 0 0 0;
    min-height: 20px;
    text-align: center;
    color: white;
    letter-spacing: .001rem;
    -webkit-text-stroke: .5px #3c1874;
    letter-spacing: 0.05em;
  }
}

// CONTAINER AND APP STYLING
#root {
  min-height: 100vh;
  background-color: $cloud;
  background-image: url("../components/images/background_image.gif");
  background: linear-gradient( rgba(255, 255, 255, .5), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) ), url("../components/images/background_image.gif");
  background-position: center top;
  background-size: cover;
}
.app-div {
  min-height: 100vh;
}
.main-container {
  min-height: calc(100vh - 55px);
  padding: 0 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media all and (min-width: 1280px) {
    max-width: 1000px;
    margin: auto;
  }
}
.start {
  justify-content: flex-start;
  // display: block;
}
.full-width {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 55px);
  img {
    width: 100%;
  }
}
// ADD SECRETS STYLING
.add-secrets {
  max-width: 95%;
  margin: 0px auto 100px;
  // height: 100px;
  h1 {
    font-style: italic;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 60px;
    letter-spacing: 0.001rem;
    -webkit-text-stroke: 1.4px #3C1874;
  }
}
.share-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
}
.secret-input {
  width: 80%;
  min-width: 270px;
  margin-bottom: 30px;
}
.thank-you {
  margin: 30px 0;
  font-weight: bold;
  color: $teal;
  text-align: center;
}

// VIEW SECRETS
.view-secret {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.show-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  @media all and (max-width: 360px) {
    max-width: 90%;
    margin: 20px auto 0;
  }
  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 60px;
    font-style: italic;
    letter-spacing: 0.001rem;
    -webkit-text-stroke: 1.4px #3C1874;
  }
  .mint-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .hidden-message {
      font-style: italic;
      letter-spacing: 0.01rem;
      display: none;
      color: $cloud;
      text-align: center;
    }
    &.no-account {
      .btn {
        cursor: default;
        &:hover {
          background-color: $cloud !important;
          border-color: $border-color !important;
          color: $secondary-color !important;
        }
      }
      .hidden-message {
        display: block;
      }
    }
  }
}

// MINTING A SECRET
.mint-secret, .about {
  border-radius: 10px;
  width: 80%;
  max-width: 1000px;
  @media all and (max-width: 420px) {
    width: 100%;
  }

  margin: 50px auto;
  background-color: $purple-gray-transparent;
  padding: 20px 10%;
  h2 {
    text-transform: uppercase;
  }
  h2, h5, h6, p, summary {
    color: $cloud;
    cursor: default;
    letter-spacing: 0.05rem;
  }
  summary {
    margin-bottom: 15px;
    cursor: pointer;
  }
  img {
    width: 100%;
    margin-bottom: 20px;
  }
  h1, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 2rem;
    text-transform: uppercase;
    -webkit-text-stroke: 1px #15D0D4;
    font-weight: 500;
    letter-spacing: 0.001rem;
  }
  h6 {
    letter-spacing: .02rem;
  }
  h5 {
    letter-spacing: .05rem;
  }
  .last-item {
    margin-bottom: 40px;
  }
  h2 {
    margin-top: 10px;
    margin-bottom: 2rem;
    -webkit-text-stroke: 1.4px #3C1874;
  }
  @media (min-width: 550px) {
    h2 {
      -webkit-text-stroke: 1.8px #3C1874;
      line-height: 1.25;
      font-weight: 800;
    }
  }
}

// HEADER STYLING
.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $pure-purple;
  padding: 10px 15px;
  z-index: 2;
  height: 55px;
}
.header-title {
  font-size: 24px;
  color: white;
  font-style: italic;
  font-weight: bold;
  -webkit-text-stroke: 1px #15D0D4;
  letter-spacing: 0.05em;
  white-space: nowrap;
  &:hover {
    color: $gray-teal;
    text-decoration: none;
  }
  @media all and (max-width: 360px) {
    font-size: 20px;
  }
}
.header-right {
  color: $cloud;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    fill: $teal;
    &:hover {
      fill: $gray-teal;
    }
  }
}
.drawer {
  .MuiDrawer-paper {
    min-width: 150px;
    background-color: $purple-gray;
  }
}
.header-nav-link {
  color: white;
  text-transform: uppercase;
  letter-spacing: .001rem;
  font-weight: bold;
  -webkit-text-stroke: .5px #15D0D4;
  letter-spacing: 0.05em;
  &:hover {
    text-decoration: none;
    color: $gray-teal;
  }
  &.disabled {
    color: $gray-teal;
    cursor: default !important;
    div {
      cursor: default !important;
    }
  }
}
.etherscan {
  // display: none;
}

// CUSTOM STYLING FOR THE ALERT MODAL:
.purple {
  background-color: white !important;
  &:hover, &:focus {
    background-color: $pure-purple !important;
  }
}
.purple-background {
  color: $pure-purple !important;
  background-color: $cloud !important;
  font-size: 12px !important;
  line-height: 12px !important;
  &:hover {
    color: $cloud !important;
    background-color: $pure-purple !important;

  }
}
.dialogue-title {
  h2 {font-size: 18px;}
}
.dialogue-text {
  font-size: 14px !important;
}
